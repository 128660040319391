import React, {useState,useEffect} from 'react'
import Header from './Header'

import {
    ListGroup,
    Card,
    Container
} from 'react-bootstrap'
import {
    Link
} from "react-router-dom";

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

import {
    $http
} from '../helpers'


const Categorias = (props) => {

    const [state,setState] = useState({
        categorias: {
            data: []
        },
        loading: false
    })

    const search = async (search) => {

        setState({
            loading:true
        })

        const result = await $http.get('categorias')
        setState({
            categorias: result.data,
            loading: false
        })

    }

    useEffect(() => {
        search()
    }, []);

    const categorias = () => {

        return state.categorias.data.map((categoria,key) => {
            return <ListGroup.Item key={key}>
                        <Link to={`categorias/${categoria.id}`} title="Editar" class="btn btn-sm btn-link">
                            <Icon size="sm" icon={faEdit}></Icon>
                        </Link> 
                        {categoria.titulo}
                    </ListGroup.Item>
        })

    }

    return (
        <>
            <Header />
            <Container style={{marginTop:10}}>
                <Card>
                    <Card.Header>Lista de categorias</Card.Header>     
                    <ListGroup variant="flush">
                        {
                            state.loading ? 
                                <ListGroup.Item>Carregando categorias...</ListGroup.Item>
                            : <>
                                { state.categorias.data && state.categorias.data.length > 0 
                                    ? categorias()
                                    : <ListGroup.Item>Nenhum categoria cadastrado</ListGroup.Item>
                                }
                            </>
                        }
                    </ListGroup>
                </Card>
            </Container>
        </>
    );
};

export default Categorias;