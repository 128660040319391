import React,{useState,useEffect} from 'react'
import { useForm } from "react-hook-form";

import { 
    Card,
    Button,
    Row,
    Col
} from 'react-bootstrap'
import ProdutoVariacao from './ProdutoVariacao'


import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faPlusCircle,faTrash } from '@fortawesome/free-solid-svg-icons'

import {
    Form,
    Table,
    Image
} from 'react-bootstrap'

import ImageUploader from 'react-images-upload';

import {$http} from '../../helpers'
import {loading,warning} from '../../helpers/alerts'

import RichTextEditor from 'react-rte';

const FormProduto = props => {

    const { register, handleSubmit, errors } = useForm();
    const [ state, setState ] = useState({
        titulo: props.data.titulo,
        categoria_id: props.data.categoria_id,
        descricao: props.data.descricao,
        descricao_curta: props.data.descricao_curta,
        variacoes: props.data.variacoes,
        arquivo_id: props.data.arquivo_id,
        arquivo_destaque_id: props.data.arquivo_destaque_id,
        arquivo_url: props.data.arquivo_url,
        arquivo_destaque_url: props.data.arquivo_destaque_url,
        visivel: 'SIM'
    })

    const [categorias,setCategorias] = useState({
        categorias:[]
    })

    const [stateEditorCurto,setEditorCurto] = useState({
        value: RichTextEditor.createValueFromString(state.descricao_curta, 'html')
    })

    const [stateEditor,setEditor] = useState({
        value: RichTextEditor.createValueFromString(state.descricao, 'html')
    })

    const fetchCategorias = async () => {
        let result = await $http.get('categorias')
        setCategorias({
            categorias: result.data.data
        })
    }

    useEffect(() => {
        fetchCategorias()
    },[])

    const setVariacao = (variacao,key) => {
        let variacoes = state.variacoes
        variacoes[key] = variacao
        setState({
            ...state,
            variacoes:variacoes
        })
    }

    const deleteVariacao = (key) => {
        let variacoes = state.variacoes
        variacoes.splice(key, 1)
        setState({
            ...state,
            variacoes:variacoes
        })
    }

    const addVariacao = () => {
        let variacoes = state.variacoes
        variacoes.push({
            descricao: "",
            valor: 0,
            peso: ""
        })
        setState({
            ...state,
            variacoes:variacoes
        })
    }

    const variacoes = () => {
        return state.variacoes.map((variacao,k) =>
            {
                return <ProdutoVariacao 
                    key={k} 
                    vKey={k}
                    data={variacao} 
                    onInput={ (data) => setVariacao(data,k) }
                    onDelete={ () => deleteVariacao(k) }
                    showDelete = {state.variacoes.length > 1}

                    register={register}
                    errors={errors}
                />
            })
    }

    const setProduto = (value) => {
        setState({ 
            ...state,
            ...value
        })
    }

    const configUpload = {
        onUploadProgress: function(progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          
          loading("show",`${percentCompleted}% carregado`)
        }
    }

    const onDropInterna = async (file) => {

        let data = new FormData()
            data.append('arquivo', file[0])

        setState({
            ...state,
            uploadProcess:0
        })    

        try{

            loading("show",`aguarde...`)
            let result = await $http.post('arquivos',data,configUpload)

            setState({
                ...state,
                arquivo_id: result.data.data.id,
                arquivo_url: result.data.data.url
            })
            
            loading('hide')

        } catch(e){
            warning({
                title: 'Houve alguma divergência',
                message: e
            })
        }
        
    }

    const onDropDestaque = async (file) => {

        let data = new FormData()
            data.append('arquivo', file[0])

        setState({
            ...state,
            uploadProcess:0
        })    

        try{

            loading("show",`aguarde...`)
            let result = await $http.post('arquivos',data,configUpload)

            setState({
                ...state,
                arquivo_destaque_id: result.data.data.id,
                arquivo_destaque_url: result.data.data.url
            })
            
            loading('hide')

        } catch(e){
            warning({
                title: 'Houve alguma divergência',
                message: e
            })
        }
        
    }

    const removeArquivoInterna = () =>{
        setState({
            ...state,
            arquivo_id: null,
            arquivo_url: ''
        })
    }

    const removeArquivoDestaque = () =>{
        setState({
            ...state,
            arquivo_destaque_id: null,
            arquivo_destaque_url: ''
        })
    }
    
    
    return <>
        <Form onSubmit={handleSubmit(() => {
            props.onSave(state)
        })}>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label>Titulo</Form.Label>
                        <Form.Control 
                            value={state.titulo} 
                            onChange={ e => {
                                e.preventDefault()
                                setProduto({titulo: e.target.value}) 
                            }}

                            name="titulo"
                            isInvalid={errors.titulo}
                            ref={register({required:true})}

                            >
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group>
                        <Form.Label>Categoria</Form.Label>
                        <Form.Control 
                            as="select"
                            value={state.categoria_id} 
                            onChange={ e => {
                                e.preventDefault()
                                setProduto({categoria_id: e.target.value}) 
                            }}

                            name="categoria"
                            isInvalid={errors.categoria}
                            ref={register({required:true})}

                            >
                                <option value="">Selecione</option>
                                {categorias.categorias.map((categoria,k) => {
                                    return <option value={categoria.id} key={k}>{categoria.titulo}</option>
                                })}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Form.Group>
                <Form.Label>Descrição curta (opcional)</Form.Label>
                <RichTextEditor
                    value={stateEditorCurto.value}
                    onChange={(value) => {
                        setEditorCurto({value})
                        setProduto({descricao_curta: value.toString('html')})
                    }}
                />
                {/* <Form.Control 
                    as="textarea" 
                    value={state.descricao_curta} 
                    onChange={ e => {
                        e.preventDefault()
                        setProduto({descricao_curta: e.target.value})
                    } }

                    ></Form.Control> */}
            </Form.Group>
            <Form.Group>
                <Form.Label>Descrição (opcional)</Form.Label>
                <RichTextEditor
                    value={stateEditor.value}
                    onChange={(value) => {
                        setEditor({value})
                        setProduto({descricao: value.toString('html')})
                    }}
                />
                {/* <Form.Control 
                    as="textarea" 
                    rows="5" 
                    value={state.descricao} 
                    onChange={  e => {
                        e.preventDefault()
                        setProduto({descricao: value.toString('html')})
                    }  }
                    
                    name="descricao"
                    isInvalid={errors.descricao}
                    ref={register({required:true})}
                    ></Form.Control> */}
            </Form.Group>

            <Row>
                <Col>
                    <Card className="mt-2">
                        <Card.Header>
                            Foto destaque
                        </Card.Header>
                        <Row>
                            {
                                state.arquivo_destaque_url ? 
                                <Col>
                                    <Image src={state.arquivo_destaque_url} thumbnail />
                                    <Button onClick={removeArquivoDestaque} className="mt-2" block variant="danger">
                                        <Icon icon={faTrash} className="mr-2"></Icon>
                                        Remover
                                    </Button>
                                </Col>
                                : null
                            }
                            <Col>
                            <ImageUploader onChange={onDropDestaque} singleImage={true} label="" buttonText="Escolher foto"></ImageUploader>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col>
                    <Card className="mt-2">
                        <Card.Header>
                            Foto interna
                        </Card.Header>
                        <Row>
                            {
                                state.arquivo_url ? 
                                <Col>
                                    <Image width={250} src={state.arquivo_url} thumbnail />
                                    <Button onClick={removeArquivoInterna} className="mt-2" block variant="danger">
                                        <Icon icon={faTrash} className="mr-2"></Icon>
                                        Remover
                                    </Button>
                                </Col>
                                : null
                            }
                            <Col>
                            <ImageUploader onChange={onDropInterna} singleImage={true} label="" buttonText="Escolher foto"></ImageUploader>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Card className="mt-2">
                <Card.Header>Opções</Card.Header>
                <Card.Body>
                    <Table>
                        <thead>
                            <tr>
                                <th>Descrição</th>
                                <th>Preço</th>
                                <th>Peso</th>
                                <th style={{width:'1%'}}>
                                    <Icon icon={faPlusCircle} onClick={addVariacao}></Icon>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {variacoes()}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            
            <Row className=" mt-2">
                <Col>
                     { props.drop ?
                     <Button variant="danger" onClick={props.onRemover}>
                        <Icon icon={faTrash}></Icon>
                     </Button>
                     : null }
                </Col>
                <Col className="text-right"><Button type="submit">Salvar</Button></Col>
            </Row>
        </Form>
    </>;
}

export default FormProduto;