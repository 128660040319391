import React, {useState,useEffect} from 'react'
import Form from './FormProduto'
import Header from '../Header'

import { useParams } from "react-router-dom";

import {$http} from '../../helpers'
import {loading,warning,success} from '../../helpers/alerts'
import { useHistory, Link } from "react-router-dom";

import {
    Card,
    Container
} from 'react-bootstrap'

const Novo = props => {

    const [state,setState] = useState({
        loading: false
    })
    const [produto,setProduto] = useState({
        titulo: '',
        descricao: '',
        descricao_curta: '',
        variacoes: [{
            descricao: "Inteiro",
            valor: 0,
            peso: ""
        }],
        arquivo_id: null,
        arquivo_destaque_id: null,
        visivel: 'SIM',
        caegoria_id: null
    })

    let history = useHistory();

    const save = async (data) => {
        
        setProduto(data)

        try{

            loading('show')

            let result = await $http.post('produtos',data)

            if(result.status != 201) throw result.data

            await success({
                title: 'Salvo com sucesso'
            })

            history.push('/')

        }catch(e){
            warning({
                title:'Houve alguma divergência',
                message: e
            })
        }

    }

    // quando o component é montado
    useEffect(() => {
    },[])
    

    return (<>
        <Header />
        <Container style={{marginTop:10}}>
            <Card>
                <Card.Header>Editar produto</Card.Header>
                <Card.Body>
                    {!state.loading ?
                    <Form data={produto} onSave={save} />
                    :
                    'Carregando'
                    }
                </Card.Body>
            </Card>
        </Container>
    </>);
}

export default Novo;