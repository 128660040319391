import React, {useEffect,useState, Fragment} from 'react'
import Header from './Header'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { searchProdutos } from '../actions';

import {$http} from '../helpers'

import {
    ListGroup,
    Card,
    Container,
    Form,
    Row,
    Col
} from 'react-bootstrap'
import {
    Link
} from "react-router-dom";

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'

const mapStateToProps = store => {
    return {
        produtos: store.produtosState.produtos && store.produtosState.produtos.data ? store.produtosState.produtos : {data:[]},
        loading: store.produtosState.loading
    }
};

const mapDispatchToProps = dispatch => {

  const actions = bindActionCreators({ searchProdutos }, dispatch)

  return actions;
};

const Produtos = (props) => {

    const search = (search) => props.searchProdutos(search)


    const [state,setState] = useState({
        categorias: {
            data: []
        },
        categoria_id: null
    })

    const fetchCategorias = async () => {
        let result = await $http.get('categorias')
        setState({
            categorias: result.data
        })
    }


    useEffect(() => {
        fetchCategorias()
        search({
            search: '',
            categoria_id: state.categoria_id,
            page: 1
        })
    }, []);

    const produtos = () => {

        return props.produtos.data.map((produto,key) => {
            return <ListGroup.Item key={key}>
                        <Link to={`produtos/${produto.id}`} title="Editar" class="btn btn-sm btn-link">
                            <Icon size="sm" icon={faEdit}></Icon>
                        </Link> 
                        {produto.categoria.titulo} - {produto.titulo}
                    </ListGroup.Item>
        })

    }

    return (
        <>
            <Header />
            <Container style={{marginTop:10}}>
                <Card>
                    <Card.Header>
                    <Row>
                        <Col>
                            Lista de produtos
                        </Col>
                        <Col>
                        <Form.Control as="select" onChange={(e) => { 
                            setState({
                                ...state,
                                categoria_id: e.target.value == 'todas' ? null : e.target.value
                            }) 
                            search({
                                categoria_id: e.target.value == 'todas' ? null : e.target.value
                            })
                        }}>
                            <option value='todas' >Categorias...</option>
                            {state.categorias.data.map((categoria,k) => {
                                return <option key={k} value={categoria.id}>{categoria.titulo}</option>
                            })}
                        </Form.Control>
                        </Col>
                    </Row>
                    </Card.Header>     
                    <ListGroup variant="flush">
                        {
                            props.loading ? 
                                <ListGroup.Item>Carregando produtos...</ListGroup.Item>
                            : <>
                                { props.produtos.data && props.produtos.data.length > 0 
                                    ? produtos()
                                    : <ListGroup.Item>Nenhum produto cadastrado</ListGroup.Item>
                                }
                            </>
                        }
                    </ListGroup>
                </Card>
            </Container>
        </>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Produtos);