import React, {useState} from 'react'
import {Form} from 'react-bootstrap'

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
// import CurrencyInput from 'react-currency-input';

// import IntlCurrencyInput from "react-intl-currency-input"
import IntlCurrencyInput from "../../masks/currency/index"

const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
};
  
const normalizeValue = function normalizeValue(number) {
    
       var numDigits = 2; // all input numbers must be a float point (for the cents portion). This is a fallback in case of integer ones.

    var safeNumber = Number.isInteger(number) ? Number(number) * 100 : number; // strips everything that is not a number (positive or negative)
    // then divide it by 10 power the maximum fraction digits.

    return Number(safeNumber.toString().replace(/[^0-9-]/g, '')) / Math.pow(10, numDigits);
  };

const ProdutoVariacao = (props) => {

    // const [state,setState] = useState({
    //     descricao: props.descricao,
    //     valor: props.valor,
    //     peso: props.peso
    // })
    const { register,errors,data } = props;

    const setVariacao = (value) => {
        props.onInput({
            ...data,
            ...value
        }) 
    }

    const minValor = (valor) => {
        let v = normalizeValue(valor) > 0
        return v
    }

    return (<>
        <tr>
            <th>
                <Form.Control 
                    value={data.descricao} 
                    onChange={e => {
                        e.preventDefault()
                        setVariacao({ descricao: e.target.value })
                    }}
                    name={`descricao_variacao_${props.vKey}`}
                    isInvalid={errors[`descricao_variacao_${props.vKey}`]}
                    ref={register({required:true})}
                    ></Form.Control>
            </th>
            <th>
            <IntlCurrencyInput 
                currency="BRL"
                config={currencyConfig}

                className={`form-control ${errors[`valor_variacao_${props.vKey}`] ? 'is-invalid' : '' }`}
                value={data.valor}
                onChange={(e, value) => {

                    e.preventDefault()
                    setVariacao({ valor: value })

                }}
                
                name={`valor_variacao_${props.vKey}`}
                
                _ref={register({
                    validate:minValor
                })}
                />
            </th>
            <th>
                <Form.Control 
                    value={data.peso}
                    onChange={e => {
                        e.preventDefault()
                        setVariacao({ peso: e.target.value })
                    }}    
                ></Form.Control>
            </th>
            <th>
                { props.showDelete ?
                    <Icon icon={faTrash} onClick={props.onDelete}></Icon>
                    : false }
            </th>
        </tr>
    </>)

}

export default ProdutoVariacao;