import React, {useState,useEffect} from 'react'
import Form from './FormCategoria'
import Header from '../Header'

import { useParams } from "react-router-dom";

import {$http} from '../../helpers'
import {loading,warning,success} from '../../helpers/alerts'
import { useHistory } from "react-router-dom";

import {
    Card,
    Container
} from 'react-bootstrap'

const Editar = props => {

    const [state,setState] = useState({
        loading: false
    })
    const [categoria,setCategoria] = useState({
        titulo: '',
        visivel: 'SIM'
    })

    let params = useParams();
    let history = useHistory();

    const findCategoria = async () => {

        setState({loading:true})

        let result = await $http.get(`categorias/${params.categoria_id}`)

        let data = result.data.data

        setCategoria(data)
        setState({loading:false})

    }

    const save = async (data) => {
        setCategoria(data)

        try{

            loading('show')

            let result = await $http.put(`categorias/${params.categoria_id}`,data)

            if(result.status != 200) throw result.data

            await success({
                title: 'Salvo com sucesso'
            })

            history.push('/categorias')

        }catch(e){
            warning({
                title:'Houve alguma divergência',
                message: e
            })
        }
    }

    // quando o component é montado
    useEffect(() => {
        findCategoria()
    },[])

    const remover = async () => {

        loading()
        await $http.delete(`categorias/${params.categoria_id}`)
        await success({
            title: "Removido"
        })
        history.push("/categorias")

    }

    return (<>
        <Header />
        <Container style={{marginTop:10}}>
            <Card>
    <Card.Header>Editar categoria</Card.Header>
                <Card.Body>
                    {!state.loading ?
                    <Form data={categoria} onSave={save} drop onRemover={remover} />
                    :
                    'Carregando'
                    }
                </Card.Body>
            </Card>
        </Container>
    </>);
}

export default Editar;