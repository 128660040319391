import React, {useState,useEffect} from 'react'
import Form from './FormProduto'
import Header from '../Header'

import { useParams } from "react-router-dom";

import {$http} from '../../helpers'
import {loading,warning,success,info} from '../../helpers/alerts'
import { useHistory } from "react-router-dom";

import {
    Card,
    Container
} from 'react-bootstrap'

const Editar = props => {

    const [state,setState] = useState({
        loading: false
    })
    const [produto,setProduto] = useState({
        titulo: '',
        descricao: '',
        descricao_curta: '',
        variacoes: [],
        visivel: 'SIM',
        arquivo_id: null,
        arquivo_destaque_id: null,
        arquivo_url:'',
        arquivo_destaque_url:'',
        cateforia_id: null
    })

    let params = useParams();
    let history = useHistory();

    const findProduto = async () => {

        setState({loading:true})

        let result = await $http.get(`produtos/${params.produto_id}`)

        let data = result.data.data
        data.arquivo_destaque_url = data.arquivo_destaque ? data.arquivo_destaque.url : ''
        data.arquivo_url = data.arquivo ? data.arquivo.url : ''

        setProduto(data)
        setState({loading:false})

    }

    const save = async (data) => {
        setProduto(data)

        try{

            loading('show')

            let result = await $http.put(`produtos/${params.produto_id}`,data)

            if(result.status != 200) throw result.data

            await success({
                title: 'Salvo com sucesso'
            })

            history.push('/')

        }catch(e){
            warning({
                title:'Houve alguma divergência',
                message: e
            })
        }

    }

    const remover = async () => {

        loading()
        await $http.delete(`produtos/${params.produto_id}`)
        await success({
            title: "Removido"
        })
        history.push("/")

    }

    // quando o component é montado
    useEffect(() => {
        findProduto()
    },[])

    return (<>
        <Header />
        <Container style={{marginTop:10}}>
            <Card>
    <Card.Header>Editar produto</Card.Header>
                <Card.Body>
                    {!state.loading ?
                    <Form data={produto} onSave={save} onRemover={remover} drop />
                    :
                    'Carregando'
                    }
                </Card.Body>
            </Card>
        </Container>
    </>);
}

export default Editar;