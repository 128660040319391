import { SET_PRODUTOS } from './actionTypes';
import { SET_LOADING } from './actionTypes';

import {$http} from '../helpers'
import _ from 'lodash'

const loading = (payload) => {
    return {
        type: SET_LOADING,
        payload: payload
    }
}

export const searchProdutos = (options) => {
    return async (dispatch) => {
        

        dispatch(loading(true))

        let params = {}
        // params.p = options.page ? options.page : 1

        // if(options.search && /[^0-9.-]+/g.test(options.search)){
        //     params.nome = _.trim(options.search)
        // }else if (options.search){
        //     params.documento = _.replace(_.trim(options.search), /[^0-9]+/g, '')
        // }

        if(options.categoria_id) params.categoria_id = options.categoria_id

        params.l = 20

        let response = await $http.get(`produtos`,{
            params
        })

        dispatch(loading(false))
        
        dispatch({
            type: SET_PRODUTOS,
            payload: response.data
        })
    }
    
};
