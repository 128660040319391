import React  from 'react';
import {
    BrowserRouter,
    Switch,
    Route,
    HashRouter,
    Redirect,
  } from "react-router-dom";

import Login from './components/Login'
import Produtos from './components/Produtos'
import Categorias from './components/Categorias'
import EditarCategoria from './components/categorias/Editar'
import NovaCategoria from './components/categorias/Novo'
import EditarProduto from './components/produtos/Editar'
import NovoProduto from './components/produtos/Novo'

export default () => {

    // const location = useLocation();

    return (
        <HashRouter>
            <Switch>
                    <Route exact path="/login">
                        <Login />
                    </Route>
                    <RouteProtected exact path="/">
                        <Produtos />
                    </RouteProtected>
                    <RouteProtected exact path="/produtos/novo">
                        <NovoProduto />
                    </RouteProtected>
                    <RouteProtected exact path="/produtos/:produto_id">
                        <EditarProduto />
                    </RouteProtected>
                    <RouteProtected exact path="/categorias">
                        <Categorias />
                    </RouteProtected>
                    <RouteProtected exact path="/categorias/novo">
                        <NovaCategoria />
                    </RouteProtected>
                    <RouteProtected exact path="/categorias/:categoria_id">
                        <EditarCategoria />
                    </RouteProtected>
                </Switch>
        </HashRouter>
    );

    function RouteProtected({ children, ...rest }){
        
        return (
            <Route 
               {...rest}
               render={({ location }) => {
                    if(!localStorage.getItem('token')){
                        return <Redirect
                            to={{
                                pathname: '/login',
                                state: { from: location }
                            }}
                        />
                    }
                    return children
               }}/>
        )

        
    }

}