import React, { useState } from 'react'
import { 
    Container,
    Row,
    Col,
    Form,
    Button
} from 'react-bootstrap'
import { useForm } from "react-hook-form";
import Background from '../images/background.jpg'
import { useAlert, positions } from 'react-alert'
import Logo from './Logo'

import {
    $http
} from '../helpers'

import {loading} from '../helpers/alerts'

import {
    useLocation,
    useHistory
  } from "react-router-dom";

const md5 = require("md5")

export default () => {

    const [state, setState] = useState({
        email: '',
        password: '',
    });

    const alert = useAlert()

    const setEmail = (e) => {
        setState({
            ...state,
            email: e.target.value
        })
    }
    const setSenha = (e) => {
        setState({
            ...state,
            password: e.target.value
        })
    }

    const { register, handleSubmit, errors } = useForm();

    let history = useHistory();
    let location = useLocation();

    const logar = async () => {

        // if(md5(state.pin) !== process.env.REACT_APP_PIN){
        //     alert.show('PIN Inválido',{
        //         type:'error',
        //         position: positions.BOTTOM_LEFT,
        //         timeout:1000
        //     })
        //     return
        // }

        loading('show')

        let result = await $http.post('auth/login',{
            email: state.email,
            password: state.password
        })


        loading('hide')

        if(result.status != 200){
            alert.show('Dados Inválidos',{
                type:'error',
                position: positions.BOTTOM_LEFT,
                timeout:1000
            })
            return
        }

        alert.show('Sucesso...',{
            type:'success',
            position: positions.BOTTOM_LEFT,
            timeout:1500
        })

        let { from } = location.state || { from: { pathname: '/'} }

        localStorage.setItem('token',result.data.access_token)

        history.replace(from)
      
    }

    const styleColumn = {
        height:'100vh'                                                                  
    }
    
    const styleSideBar = {
        background: 'white'
    }
    
    const styleBigColumn = {
        background: `url("${Background}") 0% 0% / cover`
    }

    return (
        <Container fluid>
            <Row >
                <Col style={{...styleColumn,...styleSideBar}} className="d-flex align-items-center justify-content-center">
                    <div>
                        <Logo className="display-4"></Logo>
                        <Form className="mt-2" onSubmit={handleSubmit(logar)}>
                            <Form.Group>
                                <Form.Label>
                                    Digite o E-mail
                                </Form.Label>
                                <Form.Control onChange={setEmail} isInvalid={errors.email} name="email" type="text" ref={register({ required: true })}></Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>
                                    Digite a senha
                                </Form.Label>
                                <Form.Control onChange={setSenha} isInvalid={errors.senha} name="senha" type="password" ref={register({ required: true })}></Form.Control>
                            </Form.Group>
                            <Button variant="primary" type="submit">
                                Entrar
                            </Button>
                        </Form>
                    </div>
                </Col>
                <Col style={{...styleColumn,...styleBigColumn}} className="d-flex align-items-center" md="8"></Col>
            </Row>
        </Container>
    )



}
