import React,{useState,useEffect} from 'react'
import { useForm } from "react-hook-form";

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'

import { 
    Card,
    Button,
    Row,
    Col
} from 'react-bootstrap'


import {
    Form,
    Table,
    Image
} from 'react-bootstrap'


import {loading,warning} from '../../helpers/alerts'

const FormCategoria = props => {

    const { register, handleSubmit, errors } = useForm();
    const [ state, setState ] = useState({
        titulo: props.data.titulo,
        visivel: 'SIM'
    })

    const setCategoria = (value) => {
        setState({ 
            ...state,
            ...value
        })
    }

    
    return <>
        <Form onSubmit={handleSubmit(() => {
            props.onSave(state)
        })}>
            <Form.Group>
                <Form.Label>Titulo</Form.Label>
                <Form.Control 
                    value={state.titulo} 
                    onChange={ e => {
                        e.preventDefault()
                        setCategoria({titulo: e.target.value}) 
                    }}

                    name="titulo"
                    isInvalid={errors.titulo}
                    ref={register({required:true})}

                    >
                </Form.Control>
            </Form.Group>
                
            <Row className=" mt-2">
                <Col>
                     { props.drop ?
                     <Button variant="danger" onClick={props.onRemover}>
                        <Icon icon={faTrash}></Icon>
                     </Button>
                     : null }
                </Col>
                <Col className="text-right"><Button type="submit">Salvar</Button></Col>
            </Row>
        </Form>
    </>;
}

export default FormCategoria;